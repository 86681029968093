import React from 'react'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { FaInstagram, FaWhatsapp } from 'react-icons/fa'
import { FiFacebook } from 'react-icons/fi'
import { RiMessengerLine } from 'react-icons/ri'

export default function Social() {
  return (
    <div className="tour-social pompeii-red-background">
      <ListItemIcon>
        <a href="https://www.facebook.com/pompeiify" target="_blank" rel="noreferrer">
          <FiFacebook />
        </a>
      </ListItemIcon>
      <ListItemIcon>
        <a href="https://www.instagram.com/pompeiify.it/" target="_blank" rel="noreferrer">
          <FaInstagram />
        </a>
      </ListItemIcon>
      <ListItemIcon>
        <a href="https://www.facebook.com/messages/t/108435267953060" target="_blank" rel="noreferrer">
          <RiMessengerLine />
        </a>
      </ListItemIcon>
      <ListItemIcon>
        <a href="https://wa.me/393462115715" target="_blank" rel="noopener noreferrer">
          <FaWhatsapp />
        </a>
      </ListItemIcon>
    </div>
  )
}
