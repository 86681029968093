import React from 'react'
import Social from 'components/modules/Social/Social'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

const Contacts = () => {
  const { t } = useTranslation()

  return (
    <div className="contacts-container">
      <div>
        <h2 className="contact-section-title left">
          <Trans>{t('contacts.titleLeft')}</Trans>
        </h2>
        <p className="contact-section-secondary left">
          <Trans>{t('contacts.descriptionLeft')}</Trans>
        </p>
      </div>
      <div>
        <h2 className="contact-section-title center">
          <Trans>{t('contacts.titleCenter')}</Trans>
        </h2>
        <Social />
        <AniLink className="canc-policy" to="/cancel-policy" direction="up" cover bg="#A22E37" duration={0.8}>
          <p>
            <Trans>{t('cancelPolicy.title')}</Trans>
          </p>
        </AniLink>
      </div>
      <div>
        <h2 className="contact-section-title right">
          <Trans>{t('contacts.titleRight')}</Trans>
        </h2>
        <p className="contact-section-secondary right">
          <Trans>{t('contacts.descriptionRight')}</Trans>
        </p>
      </div>
    </div>
  )
}
export default Contacts
